import Helmet from 'react-helmet';
import React from 'react';

import { GatsbyPageProps } from '../types/Gatsby';
import Headline from '../components/Headline';
import Layout from '../components/Layout';

import Connect0 from '../images/shapes/connect-0.svg';

import classNames from 'classnames/bind';
import style from './404.module.scss';
const cx = classNames.bind(style);

export const PageNotFound: React.FC<GatsbyPageProps<never>> = (props) => {
    return (
        <Layout hasInverseHeader>
            <Helmet title="Not Found" />
            <main className={cx('main', 'inverse')} role="main">
                <Connect0 className={cx('image')} />
                <Headline className={cx('title')}>Not Found</Headline>
                <p className={cx('lead')}>There’s nothing to show here.</p>
            </main>
        </Layout>
    );
};

export default PageNotFound;
